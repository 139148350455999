import styles from '@convelio/stylio/styles.module.scss';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, styled, useTheme } from '@mui/material';
import { BaseSyntheticEvent } from 'react';

import { FileCategoryType } from '../../../../../../types/FileCategoryType';
import { FileToUpload } from '../../../../../types/booking/File';

type Props = {
  label?: string;
  files?: FileToUpload[];
  category: FileCategoryType;
  handleAddFile: (files: File[], target: FileCategoryType) => void;
  isLoading: boolean;
};

function SelectFile({
  category,
  label,
  files,
  handleAddFile
}: Props): JSX.Element {
  const theme = useTheme();

  const handleChange = (event: BaseSyntheticEvent) => {
    handleAddFile(Array.from(event.target.files), category);
  };

  return (
    <SelectFileMainBox>
      <label htmlFor={label}>
        <input
          hidden={true}
          type="file"
          accept=".jpg, .jpeg, .png, .pdf, .heic"
          capture="environment"
          name={label}
          id={label}
          multiple={true}
          onChange={handleChange}
          onTouchStart={handleChange}
          data-testid={`select-file-${category?.toLowerCase()}-input`}
        />
        <SelectFileButton>
          <SelectFileButtonBox>
            <SelectFileCategoryTitleBox>
              <SelectFileCategoryTitle>{label}</SelectFileCategoryTitle>
              <SelectNumberOfFilesBox>
                <SelectNumberOfFiles
                  sx={{
                    backgroundColor:
                      files && files?.length > 0
                        ? theme.palette.primary.dark
                        : theme.palette.grey['400']
                  }}
                >
                  {files?.length}
                </SelectNumberOfFiles>
                {files?.length && files?.length > 0 ? (
                  <KeyboardArrowDown />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </SelectNumberOfFilesBox>
            </SelectFileCategoryTitleBox>
            <SelectFileFilesTitle>
              {files &&
                files.map((uploadFile) => (
                  <SelectFileFileTitle key={uploadFile.file.name}>
                    {uploadFile.file.name}
                  </SelectFileFileTitle>
                ))}
            </SelectFileFilesTitle>
          </SelectFileButtonBox>
        </SelectFileButton>
      </label>
    </SelectFileMainBox>
  );
}

export default SelectFile;

const SelectFileButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  textAlign: 'left',
  flexWrap: 'wrap',
  width: '100%'
}));

const SelectFileCategoryTitleBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}));

const SelectFileCategoryTitle = styled(Box)(() => ({
  width: '100%',
  textAlign: 'left'
}));

const SelectFileFilesTitle = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  marginTop: styles.cvoSpacingM,
  fontWeight: '400',
  color: theme.palette.grey['500']
}));

const SelectFileFileTitle = styled(Box)(() => ({
  '&:not(:last-child)': {
    marginBottom: styles.cvoSpacingS
  }
}));

const SelectFileButton = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: styles.cvoSpacingM,
  borderRadius: styles.cvoSpacingXs,
  border: `1px solid ${theme.palette.grey['200']}`,
  color: theme.palette.common.black,
  fontSize: '14px',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: theme.palette.grey['100'],
    border: `1px solid ${theme.palette.grey['200']}`
  }
}));

const SelectNumberOfFilesBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
}));

const SelectNumberOfFiles = styled(Box)(({ theme }) => ({
  padding: '0px 2px 0px 2px',
  borderRadius: '100px',
  color: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30px',
  height: '24px',
  marginRight: styles.cvoSpacingM
}));

const SelectFileMainBox = styled(Box)({
  paddingTop: styles.cvoSpacingM,
  display: 'flex',
  flexDirection: 'column'
});

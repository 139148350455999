import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  BoxProps,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography,
  styled
} from '@mui/material';
import { BaseSyntheticEvent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans } from 'react-i18next';
import { Button } from 'src/app/components/Button';
import {
  ALLOWED_CRATING_IMAGE_FORMATS,
  ALLOWED_IMAGE_FORMATS
} from 'src/app/types/booking/File';
import {
  CrateFormFile,
  CrateFormFileCategory,
  PackingBookingFile
} from 'src/types/Packing';
import { getFileNameWithoutUuid } from 'src/utils/helpers/FileHelper';
import { v4 as uuidv4 } from 'uuid';

const MainWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 4,
  marginRight: 4,
  marginTop: 16
});

type Props = {
  index: number;
  title: string;
  disabled: boolean;
  files?: PackingBookingFile[];
  category: CrateFormFileCategory;
  handleDeleteFile?: (index: number, fileId: string) => void;
  handleAddFile?: (index: number, values: CrateFormFile[]) => void;
};

function PackingCratePicture({
  index,
  title,
  disabled,
  category,
  files,
  handleAddFile,
  handleDeleteFile
}: Props): JSX.Element {
  const handleDelete = (fileId: string) => {
    if (!handleDeleteFile) return;
    handleDeleteFile(index, fileId);
  };

  const handleFiles = useCallback(
    (inputFiles: File[]): void => {
      if (!handleAddFile) return;
      const eventFiles: CrateFormFile[] = inputFiles.map((file) => ({
        category,
        id: uuidv4(),
        file: file as File
      }));
      handleAddFile(index, eventFiles);
    },
    [handleAddFile, index, category]
  );

  const onFileInputChange = useCallback(
    (event: BaseSyntheticEvent): void => {
      event.target.value = '';
      handleFiles(Array.from(event.target.files));
    },
    [handleFiles]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]): void => {
      handleFiles(acceptedFiles);
    },
    [handleFiles]
  );

  const { getRootProps, getInputProps, isDragActive, isFileDialogActive } =
    useDropzone({
      onDrop,
      accept: ALLOWED_CRATING_IMAGE_FORMATS,
      disabled: disabled
    });

  return (
    <MainWrapper>
      <Typography
        variant="body2"
        color={disabled ? 'grey.400' : 'grey.600'}
        sx={{ mb: 8 }}
      >
        {title}
      </Typography>
      <label htmlFor={`${category}-${index}`}>
        <DragDropStyled
          disabled={disabled}
          isDragActive={isDragActive}
          isFileDialogActive={isFileDialogActive}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            data-testid={`crate-input-${category}-${index}`}
          />
          <UploadFileIcon fontSize="large" />
          <Trans i18nKey="pages.booking.pages.packing.dragPicture" />
        </DragDropStyled>
        <input
          type="file"
          hidden={true}
          multiple={true}
          disabled={disabled}
          id={`${category}-${index}`}
          name={`${category}-${index}`}
          onChange={onFileInputChange}
          accept={ALLOWED_IMAGE_FORMATS}
          capture="environment"
          data-testid={`crate-file-input-${category}-${index}`}
        />
      </label>
      {files && (
        <List dense={true}>
          {files
            .filter((file) => file.category === category)
            .map((file, index) => (
              <ListItem
                key={index}
                data-testid={`crate-file-${category}-${index}`}
                secondaryAction={
                  <Button
                    dataTestid={`crate-file-delete-${category}-${index}`}
                    additionalClasses="-icon"
                    onClick={() => handleDelete(file.id)}
                    startIcon={<HighlightOffIcon fontSize="inherit" />}
                  />
                }
              >
                <ListItemAvatar>
                  <img src="/convelio-blue-crate-only.svg" />
                </ListItemAvatar>
                <ListItemText primary={getFileNameWithoutUuid(file.name)} />
              </ListItem>
            ))}
        </List>
      )}
    </MainWrapper>
  );
}

export default PackingCratePicture;

interface DragDropStyledProps extends BoxProps {
  isDragActive: boolean;
  isFileDialogActive: boolean;
  disabled: boolean;
}

const getColor = (disabled: boolean, theme: Theme) =>
  disabled ? theme.palette.grey[400] : theme.palette.grey[600];

const getBackgroundColor = (
  disabled: boolean,
  isDragActive: boolean,
  isFileDialogActive: boolean,
  theme: Theme
) =>
  disabled || (!isDragActive && !isFileDialogActive)
    ? theme.palette.grey[100]
    : theme.palette.primary.light;

const getBorderColor = (
  disabled: boolean,
  isDragActive: boolean,
  isFileDialogActive: boolean,
  theme: Theme
) =>
  disabled
    ? theme.palette.grey[400]
    : isDragActive || isFileDialogActive
    ? theme.palette.primary.main
    : theme.palette.grey[400];

const getHoverStyles = (disabled: boolean, theme: Theme) =>
  !disabled && {
    cursor: 'pointer',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  };

const DragDropStyled = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isDragActive' &&
    prop !== 'isFileDialogActive' &&
    prop !== 'disabled'
})<DragDropStyledProps>(
  ({ isDragActive, isFileDialogActive, disabled, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px',
    borderStyle: 'dashed',
    padding: theme.spacing(12),
    borderRadius: theme.shape.borderRadius,
    color: getColor(disabled, theme),
    backgroundColor: getBackgroundColor(
      disabled,
      isDragActive,
      isFileDialogActive,
      theme
    ),
    borderColor: getBorderColor(
      disabled,
      isDragActive,
      isFileDialogActive,
      theme
    ),
    '&:hover': getHoverStyles(disabled, theme)
  })
);
